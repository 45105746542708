<template ref="adminUser">
  <div>
    <header-admin-pages
      title="Законопроекты"
      :counter="$store.getters.getBillDraft.length"
      :button="{ title: 'Добавить Законопроект', link: '/admin/bill_draft/create?type=create' }"
    />
    <form @reset="FormReset" @submit.prevent="FormFilers" class="filters">
      <input   placeholder="Заголовок поиска" type="text" name="name" v-model="filers.key" class="input blue">
      <select  @change="changeStatusDraft"  class="input blue"  name="type">
        <option disabled>Выберите статус законопроекта</option>
        <option v-for="item in $store.getters.getStatusBillDraft"   :key="`getStatusBillDraft${item.code}`" :value="item.code">{{ item.title }}</option>
      </select>
      <div class="wrapper-btn-filters">
        <input  class="filters-reset" type="reset" value="Сбросить фильтры">
        <input class="btn-blue" type="submit" value="Найти">
      </div>
    </form>

    <main>
      <table>
        <tr>
          <td><p class="text-content">Заголовок</p></td>
          <td><p class="text-content">Статус</p></td>
          <td><p class="text-content">Дата создания</p></td>
          <td></td>
        </tr>
        <tr v-for="(item, i) in $store.getters.getBillDraft" :key="`members${i}`">
          <td><p class="text-content">{{ item.title }}</p></td>
          <td><p class="text-content">{{ item.state }}</p></td>
          <td><p class="text-content">{{ item.date }}</p></td>
          <td>
            <drop-list @clickItem="clickItem" :idItem="item.id"/>
          </td>
        </tr>
      </table>
      <pagination/>
    </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'Members',
  data () {
    return {
      filers: {
        key: null,
        state: null
      },
      convocationList: []
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить законопроект')
        if (consent) {
          this.$store.dispatch('deleteBillDraft', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailBillDraft', value.id)
      }
    },
    FormFilers () {
      this.$store.dispatch('setFilterBillDraft', this.filers)
    },
    FormReset () {
      this.$store.dispatch('setBillDraft')
      this.filers.key = ''
    },
    changeStatusDraft (e) {
      this.filers.state = e.target.value
    }
  },
  mounted () {
    this.$store.dispatch('setBillDraft')
    this.$store.dispatch('setStatusBillDraft')
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  justify-items: baseline;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.wrapper-btn-filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}

.text-content{
  width: 15rem;
}
</style>
